export default () => {

    return (
        <div className="slide deposit size-params">
            <div className="size-params layer0">
                <div className="gift"></div>
            </div>
            <div className="layer-margin size-params layer1">
                <div className="gift"></div>
            </div>
            <div className="layer-margin size-params layer2">
                <div className="girl"></div>
            </div>
            <div className="layer-margin size-params slide-details">
                <div className="title">
                    Welcome<br/>
                    to Lucksy
                </div>
                <div className="descriptions">Get +50% up to 600 USD</div>
                <div className="descriptions-short">Collect your first deposit<br />bonus right now</div>
                <div className="details">
                    <div className="button">Claim now</div>
                    <div className="timer desktop">
                        <div className="top">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6666 8.00004C14.6666 11.68 11.6799 14.6667 7.99992 14.6667C4.31992 14.6667 1.33325 11.68 1.33325 8.00004C1.33325 4.32004 4.31992 1.33337 7.99992 1.33337C11.6799 1.33337 14.6666 4.32004 14.6666 8.00004Z" stroke="#E9974D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.4734 10.12L8.40675 8.88671C8.04675 8.67338 7.75342 8.16005 7.75342 7.74005V5.00671" stroke="#E9974D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Get bonus in time
                        </div>
                        <div className="progress">
                            <div className="line" style={{width: "50%"}}></div>
                        </div>
                        <div className="time">
                            2H 59M 59S
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}