import {useEffect, useState} from 'react';
import Slider from './slider';
import Tabs from './tabs';

export default () => {
    const [scale, setScale] = useState(1),
          [sliderScale, setSliderScale] = useState(1),
          [bottomMargin, setBottomMargin] = useState(0),
          [tabsMargin, setTabsMargin] = useState(0),
          location = "/";

    const resizeHandler = () => {
        const width = document.body.offsetWidth,
              koef = document.body.offsetWidth / 430;

        // Top block scale
        if(width <= 1267 && width > 800) {
            const nextScale = (width - 40) / 1225;

            setScale(nextScale);
            setBottomMargin((413 * nextScale) - 413);
        } else {
            setScale(1);
            setBottomMargin(0);
        }

        // Mobile slider scale
        if(width <= 800) {
            setSliderScale((width - 30) / 410);
            setTabsMargin((268 * koef) - 268);
        } else {
            setSliderScale(1);
            setTabsMargin(0);
        }
    }

    useEffect(() => {
        resizeHandler();
        
        window.addEventListener('resize', resizeHandler);
        return () => window.removeEventListener('resize', resizeHandler);
    }, [location]);

    return (
        <div className="top" style={{transform: `scale(${scale}`, marginBottom: `${bottomMargin}px`}}>
            <div className="left">
                <Slider scale={sliderScale} />
            </div>
            <div className="right" style={{marginTop: `${tabsMargin}px`}}>
                <div className="row-2">
                    <Tabs.VIP />
                    <Tabs.Wheel />    
                </div>
                <div className="row">
                    <Tabs.Refer />
                </div>
            </div>
        </div>
    )
}