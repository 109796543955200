import {useEffect} from 'react';
import PageContainer from '../page-container';
import Compontens from '../../components';

import Game from './game';
import TestYourLuck from './testyourluck';
import Actions from './actions';

export default () => {
    return (
        <PageContainer>
            <div className="page game">
                <div className="game-container">
                    {/* Mobile TestYourLuck */}
                    <Game />
                    <div className="right">
                        <TestYourLuck />
                        <Actions />
                    </div>
                </div>
                <Compontens.DepositBonus />
                <Compontens.Footer />
            </div>
        </PageContainer>
    )
} 