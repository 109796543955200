import Types from '../reducers/types';
import {dispatch} from '../lib/store';

let connection = null;

const state = {
    retries: 0,
    userId: null,
    initialized: false,
    authorized: false
}

const log = log => console.log(`[WS] ${log}`),
      send = data => connection.send(JSON.stringify(data));

const connect = () => {
    if(state.retries >= 1)
        dispatch({
            type: Types.app.connection,
            payload: 'connecting'
        });

    connection = new WebSocket(`${window.location.origin.replace('http', 'ws')}/ws`);

    connection.onopen = () => {
        log(`The connection is established!`);

        if(state.retries >= 1)
            dispatch({
                type: Types.app.connection,
                payload: 'connected'
            });

        // Переподключение по UserID
        // if(!state.authorized)
        //     log(`Authorization`);
    }

    connection.onmessage = res => {
        try {
            res = JSON.parse(res.data);
        } catch(e) {
            return log(`Wrong response`, res);
        }

        switch(res.type) {
            case 'test':
                return log(`Test message`);
            default:
                return console.log(res.type, res.payload);  
        }
    }

    connection.onclose = () => {
        log(`Connection to the server is lost. Retry in 3 seconds...`);

        state.authorized = false;
        state.retries++;

        dispatch({
            type: Types.app.connection,
            payload: 'lost'
        });

        return setTimeout(connect, 3e3);
    }
}

export default {
    connect
}