export default () => {

    return (
        <div className="col refer">
            <div className="layer-col layer0">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer1">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer2">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer4">
                <div className="money"></div>
            </div>

            <div className="layer-col layer-margin layer5">
                <div className="package"></div>
            </div>

            <div className="layer-col layer-margin layer6">
                <div className="title desktop">Refer & Earn</div>
                <div className="title mobile">Invite friends and earn!</div>
                <div className="descriptions">Invite your friends and get a bonus with real money from their deposits</div>
            </div>
        </div>
    )
}