import {useEffect, createRef, useState} from 'react';
import {NavigationLink} from '../../../../utils/navigation';
import {useLocation} from 'react-router-dom';

export default () => {
    const activeElement = createRef(),
          location = useLocation(),
          [pathname, setPathname] = useState(location.pathname),
          [hasActiveLink, setHasActiveState] = useState(false);

    useEffect(() => {
        const links = document.querySelectorAll(`.navigation .navigation-list a[href="${pathname}"]`);

        let has_active = false;

        for(const link of links) {
            if(link.getAttribute('href') != pathname) {
                if(link.classList.contains('active'))
                    link.classList.remove('active');
            } else {
                link.classList.add('active');

                if(activeElement.current) {
                    const navigation_element = document.querySelector('.navigation');
    
                    activeElement.current.style.width = `${link.offsetWidth - 14}px`;
                    activeElement.current.style.marginLeft = `${link.offsetLeft - navigation_element.offsetLeft + 7}px`;
                }

                has_active = true;
            }

            setHasActiveState(has_active);

            console.log(has_active);
        }
    }, [pathname]);

    useEffect(() => {
        setPathname(location.pathname);
        console.log(location);
    }, [location]);

    return (
        <div className="navigation">
            <div className="navigation-list">
                <NavigationLink path="/"><a href="/" onClick={e => {e.preventDefault(); setPathname('/');}}>Home</a></NavigationLink>
                <NavigationLink path="/faq"><a href="/faq" onClick={e => {e.preventDefault(); setPathname('/faq');}}>FAQ</a></NavigationLink>
                <NavigationLink path="/invite"><a href="/invite" onClick={e => {e.preventDefault(); setPathname('/invite');}}>Invite</a></NavigationLink>
                <NavigationLink path="/support"><a href="/support" onClick={e => {e.preventDefault(); setPathname('/support');}}>Support</a></NavigationLink>
            </div>
            <div className="active-element" ref={activeElement} style={{opacity: hasActiveLink ? 1 : 0}}></div>
        </div>
    )
}