import {Component} from 'react';
import {connect} from 'react-redux';
import Selector from '../selectors/layout';
import {bindActionCreators} from 'redux';
import {setMobile, initialize} from '../reducers/app/actions';
import {getUser, getTelegramUser} from '../reducers/user/actions';
import {loadGames} from '../reducers/games/actions';
import Components from './components';
import Pages from './pages';
import {Routes, Route} from 'react-router-dom';
import WS from '../lib/websocket';

class Layout extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <>
                <Components.Loader.Full />

                {this.props.loaded && (
                    <>
                        <Components.Header />
                        <Components.MobileNav />

                        <div className="container">
                            <Routes>
                                <Route exact path='/' element={<Pages.Home />} />
                                <Route exact path='/game' element={<Pages.Game />} />
                                <Route path='*' element={<Pages.NotFound />} />
                            </Routes>
                            <Components.Connection />
                        </div> 
                    </>
                )}
            </>
        )
    }

    resizeHandler() {
        const isMobile = window.document.body.offsetWidth < 1024;
        this.props.setMobile(isMobile);
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.loaded && this.props.loaded)
            Components.Loader.close();

        if(!this.props.loaded) {
            if(!this.props.user) {
                if(typeof window.Telegram.WebApp.initDataUnsafe.user !== "undefined") {
                    window.Telegram.WebApp.ready();
                    window.Telegram.WebApp.expand();
                    this.props.getTelegramUser(window.Telegram.WebApp.initData);
                } else {
                    this.props.getUser();
                }
            } else if(!this.props.games) {
                this.props.loadGames();
            }
        }
    }


    componentDidMount() {
        WS.connect();
        window.addEventListener('resize', this.resizeHandler.bind(this));

        if(!this.props.initialized)
            this.props.initialize();
    }
}

export default connect(Selector, dispatch => bindActionCreators({
    getUser,
    getTelegramUser,
    loadGames,
    setMobile,
    initialize
}, dispatch)) (Layout);