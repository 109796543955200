import {useEffect, createRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import jQuery from 'jquery';

export default ({children}) => {
    const pageContainer = createRef(),
          location = useLocation(),
          navigate = useNavigate();

    const locationChangeHandler = e => {
        if(pageContainer.current) {
            pageContainer.current.classList.remove('animate__fadeIn');
            pageContainer.current.classList.add('animate__fadeOut');
        }

        jQuery('html').animate({
            scrollTop: 0
        }, {
            duration: 490,
            easing: 'linear'
        });

        setTimeout(() => navigate(e.detail), 500);
    }

    useEffect(() => {
        window.addEventListener('location.change', locationChangeHandler);
        return () => window.removeEventListener('location.change', locationChangeHandler);
    }, [location])

    return (
        <div className="page-container animate__animated animate__faster animate__fadeIn" ref={pageContainer}>
            {children}
        </div>
    )
}