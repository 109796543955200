export default () => {

    return (
        <div className="game-info">
            <div className="game-details">
                <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/bigbang.png)`}}></div>
                <div className="game-data">
                    <div className="left">
                        <div className="title">Big Bang</div>
                        <div className="descriptions">Slots, Tables Games</div>
                    </div>
                    <div className="play">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 9.00004V6.33004C3 3.01504 5.3475 1.65754 8.22 3.31504L10.5375 4.65004L12.855 5.98504C15.7275 7.64254 15.7275 10.3575 12.855 12.015L10.5375 13.35L8.22 14.685C5.3475 16.3425 3 14.985 3 11.67V9.00004Z" fill="white"/>
                        </svg>

                        <span>Play Game</span>
                    </div>
                </div>
            </div>

            <div className="line"></div>

            <div className="finance-block">
                <div className="top">
                    <div className="balance">
                        <div className="amount">
                            <span>$1 258.01</span>
                            Balance
                        </div>
                        <div className="update">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3332 9.99996C18.3332 14.6 14.5998 18.3333 9.99984 18.3333C5.39984 18.3333 2.5915 13.7 2.5915 13.7M2.5915 13.7H6.35817M2.5915 13.7V17.8666M1.6665 9.99996C1.6665 5.39996 5.3665 1.66663 9.99984 1.66663C15.5582 1.66663 18.3332 6.29996 18.3332 6.29996M18.3332 6.29996V2.13329M18.3332 6.29996H14.6332" stroke="#DFDFEC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <span>Last update <br/> 11 Aug 11:40 PM</span>
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <div className="button addcash">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.7085 12.6041C8.7085 13.4933 9.39601 14.2083 10.2393 14.2083H11.9627C12.696 14.2083 13.2918 13.585 13.2918 12.8058C13.2918 11.9716 12.9252 11.6691 12.3843 11.4766L9.62516 10.5141C9.08433 10.3216 8.71767 10.0283 8.71767 9.18497C8.71767 8.41497 9.31349 7.78247 10.0468 7.78247H11.7702C12.6135 7.78247 13.301 8.49747 13.301 9.38664" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11 6.875V15.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.1668 11C20.1668 16.06 16.0602 20.1667 11.0002 20.1667C5.94016 20.1667 1.8335 16.06 1.8335 11C1.8335 5.94004 5.94016 1.83337 11.0002 1.83337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M15.5835 2.75V6.41667H19.2502" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.1668 1.83337L15.5835 6.41671" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Add Cash
                    </div>
                    <div className="button">
                        <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.2085 12.6041C9.2085 13.4933 9.89601 14.2083 10.7393 14.2083H12.4627C13.196 14.2083 13.7918 13.585 13.7918 12.8058C13.7918 11.9716 13.4252 11.6691 12.8843 11.4766L10.1252 10.5141C9.58433 10.3216 9.21767 10.0283 9.21767 9.18497C9.21767 8.41497 9.81349 7.78247 10.5468 7.78247H12.2702C13.1135 7.78247 13.801 8.49747 13.801 9.38664" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M11.5 6.875V15.125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.6668 11C20.6668 16.06 16.5602 20.1667 11.5002 20.1667C6.44016 20.1667 2.3335 16.06 2.3335 11C2.3335 5.94004 6.44016 1.83337 11.5002 1.83337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M20.6667 5.50004V1.83337H17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.0835 6.41671L20.6668 1.83337" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Cash Out
                    </div>
                </div>
            </div>

            <div className="line"></div>

            <div className="login-details">
                <div className="input">
                    <div className="value">qk_687122</div>
                    <div className="icons">
                        <div className="icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className="input">
                    <div className="value">••••••</div>
                    <div className="icons">
                        <div className="icon">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3866 8.00007C10.3866 9.32007 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32007 5.61328 8.00007C5.61328 6.68007 6.67995 5.6134 7.99995 5.6134C9.31995 5.6134 10.3866 6.68007 10.3866 8.00007Z" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.9999 13.5134C10.3532 13.5134 12.5466 12.1267 14.0732 9.72671C14.6732 8.78671 14.6732 7.20671 14.0732 6.26671C12.5466 3.86671 10.3532 2.48004 7.9999 2.48004C5.64656 2.48004 3.45323 3.86671 1.92656 6.26671C1.32656 7.20671 1.32656 8.78671 1.92656 9.72671C3.45323 12.1267 5.64656 13.5134 7.9999 13.5134Z" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3327 10.7501V14.2501C13.3327 17.1668 12.166 18.3335 9.24935 18.3335H5.74935C2.83268 18.3335 1.66602 17.1668 1.66602 14.2501V10.7501C1.66602 7.83348 2.83268 6.66681 5.74935 6.66681H9.24935C12.166 6.66681 13.3327 7.83348 13.3327 10.7501Z" stroke="none"/>
                                <path d="M14.249 1.66681H10.749C8.16562 1.66681 6.99895 2.58348 6.74895 4.75014C6.66562 5.25014 7.08229 5.58348 7.58229 5.58348H9.33229C12.8323 5.58348 14.4156 7.16681 14.4156 10.6668V12.5001C14.4156 13.0001 14.8323 13.3335 15.249 13.3335C17.4156 13.0835 18.3323 11.9168 18.3323 9.33348V5.75014C18.3323 2.83348 17.1656 1.66681 14.249 1.66681Z" stroke="none" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="login-actions">
                <div className="reset">Reset password</div>
                <div className="remove">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21 5.98004C17.67 5.65004 14.32 5.48004 10.98 5.48004C9 5.48004 7.02 5.58004 5.04 5.78004L3 5.98004" stroke="#D51F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M8.5 4.97006L8.72 3.66006C8.88 2.71006 9 2.00006 10.69 2.00006H13.31C15 2.00006 15.13 2.75006 15.28 3.67006L15.5 4.97006" stroke="#D51F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.8499 9.14008L18.1999 19.2101C18.0899 20.7801 17.9999 22.0001 15.2099 22.0001H8.7899C5.9999 22.0001 5.9099 20.7801 5.7999 19.2101L5.1499 9.14008" stroke="#D51F22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}