import types from './types';
import API from '../../lib/api';

export const loadGames = () => async dispatch => {
    const response = await API.call({
        path: '/games/get'
    });

    if(response.success)
        dispatch({
            type: types.loaded,
            payload: response.games
        });
}