import types from './types';

const InitialState = {
    initialized: false,
    connection: null,
    isMobile: false
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.mobile:
            return {
                ...state,
                isMobile: payload
            }
        case types.connection:
            return {
                ...state,
                connection: payload
            }
        case types.init:
            return {
                ...state,
                initialized: true
            }
        default:
            return state;
    }
}