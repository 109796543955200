import {navigate} from '../../../../utils/navigation';

export default ({
    game_key,
    name
}) => {

    return (
        <div className="game" onClick={e => navigate('/game')}>
            <div className="image" style={{backgroundImage: `url(${window.location.origin}/source/system/games/${game_key}.png)`}}></div>
            <div className="details">
                <div className="name">
                    <span className="text">{name}</span>
                    {game_key === "allinone" && (
                        <div className="tags">
                            <span className="new">NEW</span>
                            <span className="hot">HOT</span>
                        </div>
                    )}
                </div>
                <div className="descriptions">Slots, Tables Games</div>
            </div>
        </div>
    )
}