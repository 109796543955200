import Game from './game';

export default () => {
    const games = [
        {key: 'allinone', name: 'AllInOne'},
        {key: 'mafia', name: 'Mafia'},
        {key: 'cashmachine', name: 'Cash Machine'},
        {key: 'ultrapanda', name: 'Ultra Panda'},
        {key: 'orionpower', name: 'Orion Power'},
        {key: 'vegasluck', name: 'Vegas Luck'},
        {key: 'gaminator', name: 'Gaminator'},
        {key: 'gamevault', name: 'Game Vault'},
        {key: 'milkyways', name: 'Milky Ways'},
        {key: 'quake', name: 'Quake'},
        {key: 'vblink', name: 'VBlink'},
        {key: 'winstar', name: 'WinStar'},
        {key: 'vegassweeps', name: 'Vegas Sweeps'},
        {key: 'bigbang', name: 'Big Bang'},
        {key: 'gameroom', name: 'Game Room'},
        {key: 'juwa', name: 'Juwa'},
        {key: 'orionstars', name: 'Orion Stars'},
        {key: 'firekirin', name: 'Fire Kirin'},
        {key: 'pandamaster', name: 'Panda Master'},
        {key: 'noble', name: 'Noble 777'},
        {key: 'gem', name: 'Gem 777'},
        {key: 'exito', name: 'Exito 777'}
    ]


    return (
        <div className="games">
            <div className="title">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.4167 10.8333H20.5834C22.7501 10.8333 23.8334 9.74996 23.8334 7.58329V5.41663C23.8334 3.24996 22.7501 2.16663 20.5834 2.16663H18.4167C16.2501 2.16663 15.1667 3.24996 15.1667 5.41663V7.58329C15.1667 9.74996 16.2501 10.8333 18.4167 10.8333Z" fill="url(#paint0_linear_325_5313)"/>
                    <path d="M5.41675 23.8333H7.58341C9.75008 23.8333 10.8334 22.75 10.8334 20.5833V18.4166C10.8334 16.25 9.75008 15.1666 7.58341 15.1666H5.41675C3.25008 15.1666 2.16675 16.25 2.16675 18.4166V20.5833C2.16675 22.75 3.25008 23.8333 5.41675 23.8333Z" fill="url(#paint1_linear_325_5313)"/>
                    <path d="M6.50008 10.8333C8.89331 10.8333 10.8334 8.89319 10.8334 6.49996C10.8334 4.10673 8.89331 2.16663 6.50008 2.16663C4.10685 2.16663 2.16675 4.10673 2.16675 6.49996C2.16675 8.89319 4.10685 10.8333 6.50008 10.8333Z" fill="url(#paint2_linear_325_5313)"/>
                    <path d="M19.5001 23.8333C21.8933 23.8333 23.8334 21.8932 23.8334 19.5C23.8334 17.1067 21.8933 15.1666 19.5001 15.1666C17.1068 15.1666 15.1667 17.1067 15.1667 19.5C15.1667 21.8932 17.1068 23.8333 19.5001 23.8333Z" fill="url(#paint3_linear_325_5313)"/>
                    <defs>
                        <linearGradient id="paint0_linear_325_5313" x1="14.7758" y1="13.4333" x2="28.0723" y2="11.9461" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4FACFE"/>
                            <stop offset="1" stop-color="#00D1DB"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_325_5313" x1="1.77577" y1="26.4333" x2="15.0723" y2="24.9461" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4FACFE"/>
                            <stop offset="1" stop-color="#00D1DB"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_325_5313" x1="1.77577" y1="13.4333" x2="15.0723" y2="11.9461" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4FACFE"/>
                            <stop offset="1" stop-color="#00D1DB"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_325_5313" x1="14.7758" y1="26.4333" x2="28.0723" y2="24.9461" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#4FACFE"/>
                            <stop offset="1" stop-color="#00D1DB"/>
                        </linearGradient>
                    </defs>
                </svg>

                Our games
            </div>

            <div className="games-list">
                {games.map((game, key) => {
                    return <Game key={key} name={game.name} game_key={game.key}  />
                })}
            </div>
        </div>
    )
}