export default () => {

    return (
        <div className="deposit_bonus">
            <div className="layer"><div className="gift"></div></div>
            <div className="layer"><div className="gift"></div></div>
            <div className="layer details">
                <div className="left">
                    <div className="gift"></div>
                    <div className="text">
                        <div className="title">Get +50% to your first deposit</div>
                        <div className="descriptions">Make a deposit now and take a bonus of up to $600 to your deposit</div>
                    </div>
                </div>

                <div className="right">
                    <div className="button">Claim now</div>
                    <div className="timer">
                        <div className="top">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6666 8.49998C14.6666 12.18 11.6799 15.1666 7.99992 15.1666C4.31992 15.1666 1.33325 12.18 1.33325 8.49998C1.33325 4.81998 4.31992 1.83331 7.99992 1.83331C11.6799 1.83331 14.6666 4.81998 14.6666 8.49998Z" stroke="#E9974D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.4734 10.62L8.40675 9.38665C8.04675 9.17332 7.75342 8.65999 7.75342 8.23999V5.50665" stroke="#E9974D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            Get in time
                        </div>
                        <div className="progress">
                            <div className="line" style={{width: '50%'}}></div>
                        </div>
                        <div className="time">2H 59M 59S</div>
                    </div>
                </div>
            </div>
        </div>
    )
}