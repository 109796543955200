import types from './types';

const InitialState = {
    loaded: false,
    user: null
}

export default (state = InitialState, {type, payload}) => {
    switch(type) {
        case types.loaded:
            return {
                ...state,
                loaded: true,
                user: payload.user
            }
        default:
            return state;
    }
}