export default () => {

    return (
        <div className={`game-testyourluck`}>
            <div className="inform">
                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_378_2021)">
                        <path d="M23.1045 19.4772C23.186 20.0954 23.4185 20.6847 23.782 21.1945C24.093 21.6313 24.4924 21.9995 24.9551 22.2759C25.4178 22.5523 25.9336 22.7307 26.4698 22.7999C37.4291 24.2185 44.3023 17.5545 44.3023 17.5545C46.8265 15.061 46.8265 11.0209 44.3023 8.52749C43.1342 7.37418 41.4463 6.93707 39.9172 6.8517C39.7637 6.84293 39.6174 6.78414 39.5013 6.68452C39.4295 6.62439 39.3707 6.55043 39.3288 6.46715C39.2868 6.38388 39.2624 6.29303 39.2572 6.20015C39.1696 4.69042 38.7269 3.02277 37.5588 1.86946C35.0345 -0.622828 30.9414 -0.622828 28.4171 1.86946C28.417 1.86935 21.6676 8.65544 23.1045 19.4772Z" fill="#EAEAEA"/>
                        <path d="M39.5007 6.68448L23.7826 21.1933L23.7815 21.1944C23.418 20.6847 23.1855 20.0953 23.104 19.4772C21.6672 8.6555 28.4166 1.8693 28.4166 1.8693C30.7785 -0.462716 34.5142 -0.612618 37.0524 1.41948C37.2288 1.56072 37.3976 1.71086 37.5583 1.8693C38.7264 3.02261 39.1691 4.69038 39.2567 6.19999C39.2619 6.29288 39.2862 6.38374 39.3282 6.46703C39.3702 6.55033 39.4289 6.62431 39.5007 6.68448Z" fill="#A8C1AB"/>
                        <path d="M22.8397 19.4773C22.7583 20.0955 22.5257 20.6849 22.1622 21.1946C22.1587 21.1991 22.1564 21.2038 22.1529 21.2073C21.8418 21.6408 21.4433 22.0061 20.9823 22.2802C20.5214 22.5543 20.008 22.7313 19.4744 22.8C8.51517 24.2186 1.64197 17.5547 1.64197 17.5547C-0.412804 15.5249 -0.794681 12.4686 0.495999 10.0581C0.798245 9.49416 1.18454 8.97825 1.64197 8.52761C2.81006 7.37431 4.49796 6.93719 6.02705 6.85182C6.18058 6.84306 6.32683 6.78426 6.4429 6.68464C6.51479 6.62451 6.57351 6.55055 6.61549 6.46728C6.65747 6.384 6.68182 6.29315 6.68706 6.20027C6.77466 4.69054 7.21737 3.02289 8.38547 1.86958C10.9097 -0.622706 15.0029 -0.622706 17.5271 1.86958C17.5271 1.86947 24.2765 8.65556 22.8397 19.4773Z" fill="#A8C1AB"/>
                        <path d="M22.8393 19.4772C22.7579 20.0954 22.5253 20.6847 22.1618 21.1945C22.1583 21.199 22.156 21.2037 22.1525 21.2072C10.1407 22.169 2.67883 14.7358 2.67883 14.7358C1.38928 13.4279 0.66154 11.7648 0.495605 10.0579C0.797852 9.49404 1.18415 8.97813 1.64157 8.52749C2.80966 7.37418 4.49757 6.93707 6.02665 6.8517C6.18019 6.84293 6.32644 6.78414 6.44251 6.68452C6.51439 6.62439 6.57311 6.55043 6.61509 6.46715C6.65707 6.38388 6.68143 6.29303 6.68667 6.20015C6.77426 4.69042 7.21698 3.02277 8.38507 1.86946C10.9093 -0.622828 15.0025 -0.622828 17.5267 1.86946C17.5267 1.86935 24.2761 8.65544 22.8393 19.4772Z" fill="white"/>
                        <path d="M22.8397 19.4772C22.7582 20.0954 22.5257 20.6847 22.1622 21.1945L6.44287 6.68452C6.51476 6.62439 6.57348 6.55043 6.61546 6.46715C6.65744 6.38388 6.68179 6.29303 6.68703 6.20015C6.77463 4.69042 7.21735 3.02277 8.38544 1.86946C10.9097 -0.622828 15.0029 -0.622828 17.5271 1.86946C17.5271 1.86935 24.2765 8.65544 22.8397 19.4772Z" fill="#EAEAEA"/>
                        <path d="M39.5008 6.68453L23.7827 21.1933C24.2488 10.367 30.7879 3.7919 30.7879 3.7919C32.5085 2.09415 34.7992 1.30307 37.0524 1.41954C37.2288 1.56077 37.3976 1.71091 37.5582 1.86936C38.7263 3.02266 39.1691 4.69043 39.2566 6.20004C39.2619 6.29293 39.2863 6.38378 39.3283 6.46707C39.3702 6.55036 39.4289 6.62435 39.5008 6.68453Z" fill="white"/>
                        <path d="M26.5255 22.9932C25.8993 23.0736 25.3025 23.3032 24.7862 23.6621C24.3438 23.9691 23.9708 24.3635 23.6909 24.8204C23.4109 25.2772 23.2302 25.7865 23.1601 26.3159C21.7234 37.1364 28.4728 43.9226 28.4728 43.9226C30.9982 46.4149 35.0902 46.4149 37.6155 43.9226C38.7836 42.7693 39.2264 41.1028 39.3128 39.5931C39.3217 39.4415 39.3812 39.2971 39.4821 39.1825C39.543 39.1115 39.618 39.0535 39.7023 39.0121C39.7866 38.9706 39.8787 38.9466 39.9727 38.9414C41.5018 38.8549 43.1908 38.4178 44.3589 37.2645C46.8832 34.7722 46.8832 30.7308 44.3589 28.2385C44.359 28.2385 37.4861 21.5746 26.5255 22.9932Z" fill="#EAEAEA"/>
                        <path d="M39.4821 39.182L24.7873 23.6629L24.7861 23.6617C25.3024 23.3029 25.8993 23.0733 26.5254 22.9928C37.4858 21.5742 44.359 28.2382 44.359 28.2382C46.7209 30.5702 46.8727 34.2586 44.8146 36.7647C44.6715 36.9388 44.5195 37.1055 44.359 37.2641C43.1909 38.4174 41.5018 38.8545 39.9728 38.941C39.8787 38.9461 39.7866 38.9701 39.7023 39.0115C39.6179 39.053 39.543 39.111 39.4821 39.182Z" fill="#A8C1AB"/>
                        <path d="M39.4819 39.182L24.7871 23.6628C35.7522 24.123 42.4116 30.5793 42.4116 30.5793C44.1312 32.2782 44.9324 34.5399 44.8144 36.7646C44.6714 36.9387 44.5193 37.1054 44.3588 37.264C43.1908 38.4173 41.5016 38.8544 39.9726 38.9409C39.8785 38.946 39.7865 38.9701 39.7021 39.0115C39.6178 39.053 39.5428 39.111 39.4819 39.182Z" fill="white"/>
                        <path d="M22.8395 26.3153C22.758 25.6971 22.5255 25.1077 22.162 24.598C21.851 24.1612 21.4516 23.7929 20.9889 23.5166C20.5262 23.2402 20.0104 23.0618 19.4742 22.9926C8.51492 21.574 1.64172 28.2379 1.64172 28.2379C-0.882525 30.7315 -0.882525 34.7716 1.64172 37.265C2.80981 38.4183 4.49771 38.8554 6.0268 38.9408C6.18035 38.9495 6.32662 39.0084 6.44265 39.1081C6.51454 39.1682 6.57328 39.2421 6.61525 39.3254C6.65723 39.4087 6.68158 39.4996 6.68681 39.5924C6.77441 41.1022 7.21712 42.7698 8.38522 43.9231C10.9095 46.4154 15.0026 46.4154 17.5269 43.9231C17.5269 43.9231 24.2762 37.137 22.8395 26.3153ZM25.1313 25.9951C25.2446 25.6203 25.3369 25.445 25.289 25.8094C25.275 25.9143 25.2165 25.9697 25.1313 25.9951Z" fill="#EAEAEA"/>
                        <path d="M6.44287 39.1081L22.161 24.5993L22.1621 24.5981C22.5256 25.1079 22.7581 25.6972 22.8396 26.3154C24.2763 37.137 17.527 43.9232 17.527 43.9232C15.1651 46.2553 11.4294 46.4052 8.89114 44.3731C8.7148 44.2318 8.54597 44.0817 8.38532 43.9232C7.21723 42.7699 6.77452 41.1022 6.68692 39.5926C6.68173 39.4997 6.65742 39.4088 6.61545 39.3255C6.57349 39.2422 6.51477 39.1682 6.44287 39.1081Z" fill="#A8C1AB"/>
                        <path d="M6.44287 39.1081L22.161 24.5992C21.6949 35.4256 15.1558 42.0007 15.1558 42.0007C13.4352 43.6984 11.1445 44.4895 8.89126 44.373C8.71491 44.2318 8.54608 44.0817 8.38544 43.9232C7.21735 42.7699 6.77463 41.1022 6.68703 39.5925C6.68181 39.4996 6.65746 39.4088 6.61548 39.3255C6.5735 39.2422 6.51477 39.1682 6.44287 39.1081Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_378_2021">
                            <rect width="46" height="46" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>

                <div className="data">
                    <div className="title">Test your luck</div>
                    <div className="descriptions">Get real money bonus if you are unlucky in recent games</div>
                </div>
            </div>

            <div className="button button-mobile">
                View more
            </div>

            <div className="progress">
                <div className="line-block">
                    <div className="line">
                        <div className="value" style={{width: "70%"}}></div>
                    </div>
                </div>

                <div className="points">
                    <div className="point">
                        <div className="bonus-amount" style={{opacity: 0}}>$0</div>
                        <div className="dot active"></div>
                        <div className="count">1</div>
                    </div>

                    <div className="empty"></div>

                    <div className="point">
                        <div className="bonus-amount">$5</div>
                        <div className="dot"></div>
                        <div className="count">3</div>
                    </div>

                    <div className="point">
                        <div className="bonus-amount">$10</div>
                        <div className="dot"></div>
                        <div className="count">4</div>
                    </div>

                    <div className="point">
                        <div className="bonus-amount active">$15</div>
                        <div className="dot"></div>
                        <div className="count">5</div>
                    </div>
                </div>
            </div>

            <div className="button button-desktop">
                View more
            </div>
        </div>
    )
}