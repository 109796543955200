import PageContainer from '../page-container';
import Top from './top';
import Games from './games';
import Compontens from '../../components';

export default () => {
    return (
        <PageContainer>
            <div className="page home">
                <Top />
                <Games />

                <Compontens.DepositBonus />
                <Compontens.Footer />
            </div>
        </PageContainer>
    )
}