import Full from './full';

const close = () => {
    const element = document.querySelector('.loader-overlay');

    if(element) {
        element.classList.add('animate__fadeOut');

        setTimeout(() => {
            element.remove();
        }, 1000);
    }
}

export default {
    Full,
    close
}