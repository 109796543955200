export default () => {

    return (
        <div className="game-actions">
            <div className="head">
                <div className="col id">ID</div>
                <div className="col login">Login</div>
                <div className="col type">Type</div>
                <div className="col amount">Amount</div>
                <div className="col status">Status</div>
                <div className="col date">Date</div>
            </div>
            <div className="table">
                <div className="tr">
                    <div className="col id">#M0360CF</div>
                    <div className="col login">qk_687122</div>
                    <div className="col type">Add Cash</div>
                    <div className="col amount">
                        <span className="red">$1 500.00</span>
                    </div>
                    <div className="col status">
                        <span className="canceled">
                            Canceled
                        </span>
                    </div>
                    <div className="col date">12 Feb<br />02:00 PM</div>
                </div>

                <div className="tr">
                    <div className="col id">#M0360CF</div>
                    <div className="col login">qk_687122</div>
                    <div className="col type">Cash Out</div>
                    <div className="col amount">
                        <span className="green">$1 500.00</span>
                    </div>
                    <div className="col status">
                        <span className="processing">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.22722 13.8197C7.86604 16.453 12.1399 16.4484 14.7732 13.8096C17.4064 11.1708 17.4019 6.89695 14.7631 4.26369C12.1243 1.63043 7.85041 1.63494 5.21715 4.27376C3.89968 5.59401 3.24249 7.32356 3.24516 9.05214L3.24673 10.5488" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1.74512 9.05029L3.2467 10.5487L4.74512 9.04713" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                            <b>Processing</b>
                        </span>
                    </div>
                    <div className="col date">12 Feb<br />02:00 PM</div>
                </div>

                <div className="tr">
                    <div className="col id">#M0360CF</div>
                    <div className="col login">qk_687122</div>
                    <div className="col type">Add Cash</div>
                    <div className="col amount">
                        <span className="red">$1 500.00</span>
                    </div>
                    <div className="col status">
                        <span className="done">
                            Done
                        </span>
                    </div>
                    <div className="col date">12 Feb<br />02:00 PM</div>
                </div>

                <div className="text">
                    <div className="line"></div>
                    <div className="value">LOGIN HAS BEEN CHANGED</div>
                    <div className="line"></div>
                </div>

                <div className="tr notlogin">
                    <div className="col id">#M0360CF</div>
                    <div className="col login">qk_687122</div>
                    <div className="col type">Cash Out</div>
                    <div className="col amount">
                        <span className="green">$1 500.00</span>
                    </div>
                    <div className="col status">
                        <span className="processing">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.22722 13.8197C7.86604 16.453 12.1399 16.4484 14.7732 13.8096C17.4064 11.1708 17.4019 6.89695 14.7631 4.26369C12.1243 1.63043 7.85041 1.63494 5.21715 4.27376C3.89968 5.59401 3.24249 7.32356 3.24516 9.05214L3.24673 10.5488" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1.74512 9.05029L3.2467 10.5487L4.74512 9.04713" stroke="#BABBC1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                            <b>Processing</b>
                        </span>
                    </div>
                    <div className="col date">12 Feb<br />02:00 PM</div>
                </div>
            </div>
        </div>
    )
}