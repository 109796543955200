import types from './types';
import API from '../../lib/api';

export const getUser = () => async dispatch => {
    const response = await API.call({
        path: '/auth/user/get'
    });

    if(!response.success)
        return window.alert(JSON.stringify(response));

    dispatch({
        type: types.loaded,
        payload: {
            user: response.user
        }
    });
}

export const getTelegramUser = query => async dispatch => {
    const response = await API.call({
        path: '/auth/user/telegram',
        body: {
            query
        }
    });

    if(!response.success)
        return window.alert(JSON.stringify(response));

    const userResponse = await API.call({
        path: '/auth/user/get'
    });

    if(!userResponse.success)
        return window.alert(JSON.stringify(response));

    if(userResponse.success)
        dispatch({
            type: types.loaded,
            payload: {
                user: userResponse.user
            }
        });
}