export default () => {

    return (
        <div className="col wheel">
            <div className="layer-col layer0">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer1">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer2">
                <div className="light-dot">
                    <div className="circle"></div>
                    <div className="dot"></div>
                </div>
            </div>

            <div className="layer-col layer-margin layer3">
                <div className="wheel"></div>
            </div>

            <div className="layer-col layer-margin layer4">
                <div className="wheel"></div>
            </div>

            <div className="layer-col layer-margin layer5">
                <div className="title">Daily Spin</div>
            </div>
        </div>
    )
}